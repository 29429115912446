import {createRouter, createWebHistory} from "vue-router";
import store from "../store"
const routes = [
    {
        name:"HomePage",
        path : "/",
        component: () => import("@/views/Social/Home")
    },
    {
        name:"LoginPage",
        path : "/login",
        component: () => import("@/views/auth/Login")
    },
    {
        name:"RegisterPage",
        path : "/register",
        component: () => import("@/views/auth/Register")
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, _, next) => {
    const authRequiredRoutes = ["HomePage"];
    const authNotRequiredRoutes = ["LoginPage", "RegisterPage"];
    const _isAuthenticated = store.getters._isAuthenticated;
  
    if (authNotRequiredRoutes.indexOf(to.name) > -1 && _isAuthenticated) next(false);
  
    if (authRequiredRoutes.indexOf(to.name) > -1) {
        if (_isAuthenticated){
            next();
        } 
        else {
            next({ name: "LoginPage" });
        }
    } 
    else {
        next();
    }
  });

export default router;