<template>
    <div class="music-bar" :class="{closed:!musicBar}">
        <div class="musics">
            <div class="music" v-for="track, i in tracks" :key="i" :class="{active:track.source == isActive}">
                <div class="music-img">
                    <img :src="track.cover" alt="">
                </div>
                <div class="music-text">
                    <h4>{{track.name}}</h4>
                    <h5>{{track.artist}}</h5>
                </div>
            </div>
        </div>
        <div class="music-controls">
            <div class="control-left">
                <button @click="prevTrack"><i class="fa-light fa-backward"></i></button>
                <button @click="play" class="playPause" :class="{pause:!isTimerPlaying}">
                    <i class="fa-light fa-pause"></i>
                    <i class="fas fa-play"></i>
                </button>
                <button @click="nextTrack"><i class="fa-light fa-forward"></i></button>
            </div>
            <div class="control-bar">
                <input @input="changeRange" type="range" v-model="range" min="0" max="100" id="slider">
                <div id="custom-range" :style="{ left: barWidth }">
                    <div class="rangeBtn"></div>
                </div>
                <div id="progressBar" :style="{ width: barWidth }"></div>
            </div>
            <div class="control-right">
                <button class="reverse"><i class="fa-light fa-arrow-up-arrow-down"></i></button>
                <div>
                    <button class="voice" @click="Volume0($event)" id="voiceChange" :class="{slash:!volume}">
                        <i class="fa-light fa-volume"></i> 
                        <i class="fa-light fa-volume-slash"></i>
                    </button>
                    <span><input @input="changeVolume" type="range" v-model="volume" min="0" max="100"></span>
            
                </div>
            </div>
        </div>
        <button @click="musicBar=!musicBar">
            <i class="fal fa-chevron-left"></i>
        </button>
    </div>
</template>

<script>
    import '@/assets/css/layout/musicBar.scss'
    export default{
    data() {
        return{
            musicBar:true,
            volume:100,
            lastVolume:100,
            range: 0,
            audio: null,
            circleLeft: null,
            barWidth: null,
            duration: null,
            currentTime: null,
            isTimerPlaying: false,
            tracks: [
                {
                    name: "Flaş",
                    artist: "Aga B",
                    cover: require("@/assets/media/music/cover/flas.jpg"),
                    source: require("@/assets/media/music/song/flas.mp3"),
                    favorited: false
                },
                {
                name: "Gavurlar",
                artist: "Ari Barokas",
                cover: require("@/assets/media/music/cover/gavurlar.png"),
                source: require("@/assets/media/music/song/gavurlar.mp3"),
                favorited: false
                },
                {
                name: "Risk Al",
                artist: "Aga B",
                cover: require("@/assets/media/music/cover/risk.webp"),
                source: require("@/assets/media/music/song/risk.mp3"),
                favorited: false
                },
                {
                name: "Ay Şarkısı",
                artist: "Saian",
                cover: require("@/assets/media/music/cover/saian.webp"),
                source: require("@/assets/media/music/song/saian.mp3"),
                favorited: false
                },
                {
                name: "Kennedy Yokuşu",
                artist: "Model XL",
                cover: require("@/assets/media/music/cover/model.jpg"),
                source: require("@/assets/media/music/song/model.mp3"),
                favorited: false
                },
                {
                name: "Red Bull 64 Bars",
                artist: "Kamufle x Da Poet",
                cover: require("@/assets/media/music/cover/kamufle.webp"),
                source: require("@/assets/media/music/song/kamufle.mp3"),
                favorited: false
                },
            ],
            currentTrack: null,
            currentTrackIndex: 0,
            isActive:null,
            transitionName: null
        }
    },
    components : {
    },
    methods:{
        changeRange(){
            this.isTimerPlaying = true;
            this.audio.pause();
            let maxduration = this.audio.duration;
            let percentage = this.range;
            if (percentage > 100) {
                percentage = 100;
            }
            if (percentage < 0) {
                percentage = 0;
            }
            this.barWidth = percentage + "%";
            this.circleLeft = percentage + "%";
            this.audio.currentTime = (maxduration * percentage) / 100;
            this.audio.play();
        },
        play() {
            if (this.audio.paused) {
                this.audio.play();
                this.isTimerPlaying = true;
            } else {
                this.audio.pause();
                this.isTimerPlaying = false;
            }
        },
        generateTime() {
            let width = (100 / this.audio.duration) * this.audio.currentTime;
            this.barWidth = width + "%";
            this.circleLeft = width + "%";
            let durmin = Math.floor(this.audio.duration / 60);
            let dursec = Math.floor(this.audio.duration - durmin * 60);
            let curmin = Math.floor(this.audio.currentTime / 60);
            let cursec = Math.floor(this.audio.currentTime - curmin * 60);
            if (durmin < 10) {
                durmin = "0" + durmin;
            }
            if (dursec < 10) {
                dursec = "0" + dursec;
            }
            if (curmin < 10) {
                curmin = "0" + curmin;
            }
            if (cursec < 10) {
                cursec = "0" + cursec;
            }
            this.duration = durmin + ":" + dursec;
            this.currentTime = curmin + ":" + cursec;
        },
        prevTrack() {
            this.transitionName = "scale-in";
            this.isShowCover = false;
            if (this.currentTrackIndex > 0) {
                this.currentTrackIndex--;
            } else {
                this.currentTrackIndex = this.tracks.length - 1;
            }
            this.currentTrack = this.tracks[this.currentTrackIndex];
            this.isActive = this.currentTrack.source;
            this.resetPlayer();
        },
        nextTrack() {
            this.transitionName = "scale-out";
            this.isShowCover = false;
            if (this.currentTrackIndex < this.tracks.length - 1) {
                this.currentTrackIndex++;
            } else {
                this.currentTrackIndex = 0;
            }
            this.currentTrack = this.tracks[this.currentTrackIndex];
            this.isActive = this.currentTrack.source;
            this.resetPlayer();
        },
        resetPlayer() {
            this.barWidth = 0;
            this.circleLeft = 0;
            this.audio.currentTime = 0;
            this.audio.src = this.currentTrack.source;
            setTimeout(() => {
                if(this.isTimerPlaying) {
                this.audio.play();
                } else {
                this.audio.pause();
                }
            }, 300);
        },
        changeVolume(){
            console.log(this.volume/100);
            this.audio.volume =  this.volume/100;
            console.log(this.audio.volume);
        },
        Volume0(event){
                if(this.volume){
                    this.lastVolume = this.volume;
                    this.volume = 0;
                }
                else{
                    this.volume = this.lastVolume;
                }
                this.changeVolume()
        }
    },
    created() {
        let vm = this;
        this.currentTrack = this.tracks[0];
        this.isActive = this.currentTrack.source;
        this.audio = new Audio();
        this.audio.src = this.currentTrack.source;
        this.audio.ontimeupdate = function() {
            vm.generateTime();
        };
        this.audio.onloadedmetadata = function() {
            vm.generateTime();
        };
        this.audio.onended = function() {
            vm.nextTrack();
        this.isTimerPlaying = true;
        };

        for (let index = 0; index < this.tracks.length; index++) {
            const element = this.tracks[index];
            let link = document.createElement('link');
            link.rel = "prefetch";
            link.href = element.cover;
            link.as = "image"
            document.head.appendChild(link)
        }
    }
}

</script>