import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { appAxios } from '@/utils/appAxios'


import "@/assets/font-awesome/css/all.css"
import musicBar from '@/components/Layout/bottomMusicBar.vue'
import '@/assets/css/style.scss'

const app = createApp(App)
app.component("musicBar", musicBar);
app.use(router);
app.use(store)
app.config.globalProperties.$appAxios = appAxios;
app.mount('#app')
